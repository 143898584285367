import { createFeatureSelector } from '@ngrx/store';
import {
  FinanceEntityKey,
  FinanceEntityState,
  financeEntityAdapter,
} from './finance-entity.reducer';

const selectFinanceEntityState =
  createFeatureSelector<FinanceEntityState>(FinanceEntityKey);

export const {
  selectAll: selectAllFinanceEntities,
  selectEntities: selectFinanceEntityEntities,
} = financeEntityAdapter.getSelectors(selectFinanceEntityState);
