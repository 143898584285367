import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Finance_Entity } from '@sites/data-hmm/hmm-projects';

export const financeEntityServiceActions = createActionGroup({
  source: 'DashboardStore FinanceEntityService',
  events: {
    List: emptyProps(),
    ListRefresh: emptyProps(),
    ListSuccess: props<{ financeEntities: Finance_Entity[] }>(),
    ListFailure: props<{ error: Error }>(),
  },
});
