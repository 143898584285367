import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { Finance_Entity } from '@sites/data-hmm/hmm-projects';
import { financeEntityServiceActions } from './finance-entity.actions';

export const FinanceEntityKey = 'financeEntity';

export interface FinanceEntityState extends EntityState<Finance_Entity> {
  loading: boolean;
  error?: Error;
}

export const financeEntityAdapter: EntityAdapter<Finance_Entity> =
  createEntityAdapter<Finance_Entity>();

export const initialFinanceEntityState: FinanceEntityState =
  financeEntityAdapter.getInitialState({
    loading: false,
    error: undefined,
  });

export const financeEntityReducer = createReducer(
  initialFinanceEntityState,
  on(financeEntityServiceActions.listRefresh, (state) => {
    return financeEntityAdapter.removeAll({
      ...state,
      loading: true,
      error: undefined,
    });
  }),

  on(financeEntityServiceActions.listSuccess, (state, { financeEntities }) => {
    return financeEntityAdapter.setAll(financeEntities, {
      ...state,
      loading: false,
      error: undefined,
    });
  }),
  on(financeEntityServiceActions.listFailure, (state, { error }) => {
    return financeEntityAdapter.removeAll({
      ...state,
      loading: false,
      error,
    });
  })
);
